//Please use format MSG_[message]_[name]
export const MSG_LOGIN_SUCCESS = 'Log in was successful.';
export const MSG_LOGIN_ERROR = 'Incorrect email and/or password.';
export const MSG_LOGIN_TOO_MAY_ATTEMPTS =
  'You have exceeded the maximum number of login attempts. Please reset your password.';
export const MSG_REGISTRATION_SUCCESS = 'Registration was successful.';
export const MSG_ACTIVATION_SUCCESS = 'Activation was successful.';

export const MSG_SEND_RESET_CODE_SUCCESS_EMAIL =
  'Request code sent to your email. Check the code there and enter it into this form.';
export const MSG_SEND_FORCE_PASS_REQUEST_SUCCESS =
  'Request to reset password is successfully sent.';
export const MSG_SEND_FORCE_PASS_REQUEST_ERROR = 'Error with request to reset password.';
export const MSG_SEND_RESET_CODE_SUCCESS_PHONE =
  'Request code sent to your phone. Check the code there and enter it into this form.';

export const MSG_VALID_PHONE_FORMAT = 'Please use valid phone format (123) 456-7891';
export const MSG_VALID_PHONE_AND_EMAIL_FORMAT = 'Please use valid Email/Phone (123) 456-7891';

export const MSG_FIELD_LESS_1000_CHARS = 'Field must be less than 1000 characters in length.';
export const MSG_FIELD_LESS_200_CHARS = 'Field must be less than 200 characters in length.';
export const MSG_FIELD_LESS_100_CHARS = 'Field must be less than 100 characters in length.';
export const MSG_FIELD_LESS_40_CHARS = 'Field must be less than 40 characters in length.';
export const MSG_FIELD_LESS_20_CHARS = 'Field must be less than 20 characters.';
export const MSG_FIELD_LESS_4_CHARS = 'Field must be less than 4 characters.';
export const MSG_ENGLISH_LETTERS_ONLY = 'Please use English letters and numbers only.';
export const MSG_WHITE_SPACE_ONLY = 'Field cannot contain only spaces.';
export const MSG_ASCII_CHARACTERS = 'Please use valid characters only.';

export const displayErrorStyling = (
  isFieldTouched: boolean | undefined,
  error: string | undefined | boolean
) => {
  if (!isFieldTouched) return '';
  if (isFieldTouched && error) return 'globalErrorBorder';
  if (isFieldTouched && !error) return 'globalValidBorder';
};
