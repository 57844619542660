import { ClickAwayListener } from '@mui/material';
import clsx from 'clsx';
import { InputField, StandardCheckbox } from 'components';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { FC, useEffect, useState } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import { useAppDispatch } from 'store/hooks';
import { createGoal, updateGoal } from 'store/roadmap/roadmapActions';
import {
  GoalsDTO,
  RoadmapStatus,
  createTodoInGoal,
  createTodoOrGoal,
  deleteTodoOrGoal,
  revertUpdateTodoOrGoal,
  updateTodoOrGoal
} from 'store/roadmap/roadmapSlice';
import { useIsOnlyVisibleToUserWithPermissions } from 'utils/hooks/useIsOnlyVisibleTo';
import { PERMISSION } from 'utils/protectedRoutes/userRolesAndPermissionTypes';
import * as Yup from 'yup';

import { GoalEventForm } from './goalEventForm';
import style from './roadmaps.module.scss';
import ToDo from './todo';
import TodoPopper from './todoPopper/todoPopper';

interface Props {
  providedChildren?: DraggableProvided;
  data: GoalsDTO;
  selectedDraggableId?: string | null;
  clientId: string;
}

const Goal: FC<Props> = ({ providedChildren, data, selectedDraggableId, clientId }) => {
  const dispatch = useAppDispatch();
  const [isTyping, setIsTyping] = useState<boolean>(false);
  const [toggle, setToggle] = useState<boolean>(true);
  const hasPermissionToAbandonGoal = useIsOnlyVisibleToUserWithPermissions([
    PERMISSION.UPDATE_GOAL_STATUS
  ]);

  const validationSchema = Yup.object().shape({
    title: Yup.string().max(120).required()
  });

  const { handleChange, handleBlur, handleSubmit, values, resetForm } = useFormik({
    initialValues: data,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (newData) => {
      if (data.id) {
        dispatch(updateGoal({ data: newData, clientId }));
      } else {
        dispatch(createGoal({ data: newData, clientId })).then((res: any) => {
          if (!res.error) {
            return dispatch(createTodoOrGoal({ isTodo: false }));
          }
        });
      }
      setIsTyping(false);
    }
  });

  useEffect(() => {
    if (!data.id && data.title.length === 0) {
      setIsTyping(true);
    }
  }, [data]);

  return (
    <ClickAwayListener
      onClickAway={() =>
        isTyping &&
        (!data.id
          ? dispatch(deleteTodoOrGoal(data))
          : dispatch(revertUpdateTodoOrGoal()) && resetForm()) &&
        data.status === RoadmapStatus.ACTIVE &&
        setIsTyping(false)
      }
    >
      <div
        className={clsx(
          style.item,
          style.goalWrap,
          data.status === RoadmapStatus.ABANDONED && style.abandoned,
          data.status === RoadmapStatus.COMPLETED && style.completed,
          data.orderNum.toString() === selectedDraggableId && style.selected,
          data.title === 'Uncategorized' && !data.id && style.notDraggable,
          isTyping && style.typing,
          selectedDraggableId && style.disableHover
        )}
        ref={providedChildren?.innerRef}
        {...providedChildren?.draggableProps}
      >
        <div className={style.goal}>
          <div
            {...providedChildren?.dragHandleProps}
            className={clsx('material-icons-outlined', style.dragIndicator)}
          >
            drag_indicator
          </div>
          <div className={style.content}>
            <div className={style.titleWrap}>
              <div
                className={clsx(
                  'material-icons',
                  style.toggleGoal,
                  toggle && style.toggleGoalOpened,
                  (!data.todos || data.todos?.length === 0) && style.toggleGoalDefault
                )}
                onClick={() => {
                  if (data.todos?.length) setToggle(!toggle);
                  if (toggle) {
                    const deleteGoalData =
                      data?.todos && data?.todos.length > 0 && data?.todos[data?.todos.length - 1];
                    if (deleteGoalData && !deleteGoalData.id) {
                      dispatch(
                        deleteTodoOrGoal({ ...deleteGoalData, isTodo: false, dataParent: data })
                      );
                      setIsTyping(false);
                    }
                  }
                }}
              >
                keyboard_arrow_up
              </div>
              {isTyping ? (
                <form
                  onSubmit={handleSubmit}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleSubmit();
                    }
                  }}
                >
                  <InputField
                    id="title"
                    name="title"
                    type="text"
                    placeholder="Type and hit ENTER to save"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.title}
                    classnamesProps={style.typingInput}
                    maxLength={120}
                    autoFocus
                  />
                </form>
              ) : (
                <div
                  className={style.title}
                  onDoubleClick={() => {
                    if (data.status === RoadmapStatus.ACTIVE && data.id !== null) {
                      setIsTyping(true);
                    }
                  }}
                >
                  {data.title} {data?.date && <span>({dayjs(data?.date).format('MMM D')})</span>}
                </div>
              )}
            </div>
            <div className={style.icons}>
              {data.status === RoadmapStatus.ACTIVE && (
                <>
                  <TodoPopper icon="event">
                    {({ onClose }) => <GoalEventForm goal={data} onClose={onClose} />}
                  </TodoPopper>
                  <div
                    className="material-icons"
                    onClick={() => {
                      dispatch(createTodoInGoal({ ...data }));
                      if (!toggle) setToggle(true);
                    }}
                  >
                    task
                  </div>
                  {hasPermissionToAbandonGoal && (
                    <div
                      className="material-icons"
                      onClick={() => {
                        dispatch(
                          updateGoal({
                            data: { ...data, status: RoadmapStatus.ABANDONED },
                            clientId
                          })
                        ).then((res: any) => {
                          if (!res.error) {
                            dispatch(
                              updateTodoOrGoal({
                                data: { ...res.payload.data, orderNum: data.orderNum }
                              })
                            );
                          }
                        });
                      }}
                    >
                      delete
                    </div>
                  )}
                </>
              )}
              {data.status !== RoadmapStatus.ABANDONED && hasPermissionToAbandonGoal && (
                <StandardCheckbox
                  name="status"
                  onChange={() => {
                    const statusNew =
                      data.status === RoadmapStatus.COMPLETED
                        ? RoadmapStatus.ACTIVE
                        : RoadmapStatus.COMPLETED;
                    dispatch(updateGoal({ data: { ...data, status: statusNew }, clientId })).then(
                      (res: any) => {
                        if (!res.error) {
                          dispatch(
                            updateTodoOrGoal({
                              data: { ...res.payload.data, orderNum: data.orderNum }
                            })
                          );
                        }
                      }
                    );
                  }}
                  value={data.status === RoadmapStatus.COMPLETED || false}
                  customClass={style.checkbox}
                />
              )}
              {data.status === RoadmapStatus.ABANDONED && hasPermissionToAbandonGoal && (
                <div
                  className="material-icons-outlined"
                  onClick={() => {
                    dispatch(
                      updateGoal({ data: { ...data, status: RoadmapStatus.ACTIVE }, clientId })
                    ).then((res: any) => {
                      if (!res.error) {
                        dispatch(
                          updateTodoOrGoal({
                            data: { ...res.payload.data, orderNum: data.orderNum }
                          })
                        );
                      }
                    });
                  }}
                >
                  replay_circle_filled
                </div>
              )}
            </div>
          </div>
        </div>
        {toggle && data.todos && data.todos.length > 0 && (
          <div className={style.goalTodos}>
            {data.todos.map((todo, index) => (
              <ToDo clientId={clientId} data={todo} dataParent={data} key={index} />
            ))}
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default Goal;
